<template>
  <div class="merchantPage">
    <div class="merchantPage__description">
      <h1>Merchants</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MerchantPage',
  components: {
  }
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.merchantPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0
  box-sizing border-box
  &__icon
    margin-top 70px
    font-size 140px
    display inline-block
  &__description
    text-align left
    line-height 1.6
    max-width 650px
    display inline-block
    box-sizing border-box
    font-size 14px
    /deep/ h1
      font-size 27px
      font-weight 300
      color black
    h1
      font-family: 'Bebas Neue', sans-serif;
      text-decoration-line: underline
    h2, h3
      font-family: Helvetica
      margin 30px 0 10px 0

@media screen and (max-width: 868px)
  .merchantPage
    padding 30px 10px
</style>
